import { PageProps } from "gatsby";
import React from "react";
import { graphql } from "gatsby";
import BottomCTA from "../components/BottomCta";
import AdvertisingSoftwareSection from "../components/DisplayAdvertising/AdvertisingSoftware";
import HeroSection from "../components/DisplayAdvertising/HeroSection";
import ManagedServiceSection from "../components/DisplayAdvertising/ManagedService";
import MaximizeRelevanceSection from "../components/DisplayAdvertising/MaximizeRelevance";
import SEO from "../components/Seo";
import { CTA } from "../constants/text";
import { URL } from "../constants/destinations";
import Layout from "../containers/Layout";
import { nav } from "../utils/navigation";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const DisplayAdvertisingPage: React.FC<PageProps> = props => {
  const {t} = useTranslation()
  return (
    <Layout primaryButtonId="features-da-nav-getstarted">
      <SEO
        title={t("Amazon DSP Advertising Service and Software")}
        description={t("Power the entire funnel with Perpetua and the Amazon DSP. With support for OTT, Sizmek, AMC and Custom Video Creatives, Perpetua has what you need to ampify your brand, reach more shoppers and grow.")}
      />
      <div className="space-y-20 lg:space-y-36">
        <HeroSection />
        <ManagedServiceSection />
        <AdvertisingSoftwareSection />
        <MaximizeRelevanceSection />
        <BottomCTA
          primaryButtonId="features-da-bottom-getstarted"
          secondaryButtonId="features-da-bottom-demo"
          title={t("Getting started is easy")}
          subtitle={
            t("Everything you need to grow your ecommerce business, in one platform")
          }
          primaryButtonText={t(CTA.GetStarted)}
          primaryButtonOnClick={() => {
            nav(URL.SignUp);
          }}
          secondaryButtonText={t(CTA.Demo)}
        />
      </div>
    </Layout>
  );
};

export default DisplayAdvertisingPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;