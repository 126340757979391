import classNames from "classnames";
import React from "react";
import ClickableIcon from "../ClickableIcon";
import { ClickableIconState, ClickableIconType } from "../ClickableIcon/models";

interface ToggleFeatureCardProps {
  clickableIcon: ClickableIconType;
  feature: string;
  state: ClickableIconState;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const ToggleFeatureCard: React.FC<ToggleFeatureCardProps> = props => {
  const { feature, clickableIcon, state, onClick = () => {} } = props;

  return (
    <div
      className={classNames(
        "flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5 cursor-pointer"
      )}
      onClick={onClick}
    >
      <div className="place-self-center h-14">
        <span
          className={classNames(
            "rounded-full inline-block p-4 bg-white shadow-xl",
            {
              "shadow-none": state === ClickableIconState.inactive
            }
          )}
        >
          <ClickableIcon clickableIconType={clickableIcon} state={state} />
        </span>
      </div>
      <div
        className={classNames(
          "text-xl text-center md:text-left w-60 self-center font-semibold",
          {
            "text-indigo-1": state === ClickableIconState.active,
            "text-gray-3-light": state === ClickableIconState.inactive,
            "text-gray-2-light": state === ClickableIconState.hover,
            "text-gray-1": state === ClickableIconState.pressed
          }
        )}
      >
        {feature}
      </div>
    </div>
  );
};

export default ToggleFeatureCard;
