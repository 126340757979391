export enum ClickableIconState {
  active = "active",
  inactive = "inactive",
  hover = "hover",
  pressed = "pressed"
}

export interface ClickableIconProps {
  state: ClickableIconState;
}

export enum ClickableIconType {
  chart = "chart",
  replay = "replay"
}
