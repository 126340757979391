import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { CTA } from "../../../constants/text";
import { Intercom } from "../../../constants/destinations";
import Section from "../../../containers/Section";
import Badge from "../../Badge";
import Brands from "../../Brands";
import Bayer from "../../Brands/BrandSvgs/bayer.svg";
import CalvinKlein from "../../Brands/BrandSvgs/calvinklein.svg";
import Crocs from "../../Brands/BrandSvgs/crocs.svg";
import DrTobias from "../../Brands/BrandSvgs/drtobias.svg";
import FourSigmatic from "../../Brands/BrandSvgs/foursigmatic.svg";
import HeroCosmetics from "../../Brands/BrandSvgs/herocosmetics.svg";
import Lacoste from "../../Brands/BrandSvgs/lacoste.svg";
import Umbra from "../../Brands/BrandSvgs/umbra.svg";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import Header, { HeaderVariant } from "../../Header";
import { IconType } from "../../Icon";
import NavigationBarSpacer from "../../NavigationBarSpacer";
import { Name, Type, Location } from "../../../utils/analytics/constants";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const HeroSection: React.FC<{}> = props => {
  const {t} = useTranslation();

  const graphic = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "dsp/graphic.png" }) {
        childImageSharp {
          fixed(width: 628, height: 628) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const brandsList = [
    {
      src: <Crocs />,
      filter: true
    },
    {
      src: <CalvinKlein />,
      filter: false
    },
    {
      src: <HeroCosmetics />,
      filter: false
    },
    {
      src: <Lacoste />,
      filter: false
    },
    {
      src: <FourSigmatic />,
      filter: false
    },
    {
      src: <Bayer />,
      filter: false
    },
    {
      src: <DrTobias />,
      filter: false
    },
    {
      src: <Umbra />,
      filter: false
    }
  ];

  return (
    <div className="bg-gradient-to-b from-blue-1-gradient to-white">
      <NavigationBarSpacer />
      <Section>
        <div className="flex flex-col pt-16">
          <div className="flex flex-row">
            <div className="flex flex-col">
              <Badge
                iconType={IconType.dspBlack}
                title={t("Display Advertising")}
                className="mb-12 self-center md:self-start"
              />
              <Header
                variant={HeaderVariant.h1}
                className="text-gray-3-dark text-center md:text-left"
              >
                {t("Amplify your brand, reach more shoppers, grow.")}
              </Header>
              <div className="text-xl text-gray-3-dark text-center md:text-left">
                {t("Power the entire funnel with Perpetua and the Amazon DSP. We combine AI, human creativity and industry-leading technology to put your brand in front of exactly the right audience at exactly the right time.")}
              </div>
              <Button
                id="features-da-header-contactus"
                variant={ButtonVariant.primary}
                size={ButtonSize.large}
                text={t(CTA.Contact)}
                className={"w-full md:w-max mt-10"}
              />
            </div>
            <div className="hidden lg:block self-center">
              <Img fixed={graphic?.file?.childImageSharp?.fixed} />
            </div>
          </div>
          <div className="mt-20 lg:mt-35">
            <Brands brandsList={brandsList} />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default HeroSection;
