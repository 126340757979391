import { ClickableIconState } from "./models";

export function mapStateToFill(state: ClickableIconState) {
  switch (state) {
    case ClickableIconState.active:
      return "url(#paint0_linear)";
    case ClickableIconState.inactive:
      return "#CBD5E1";
    case ClickableIconState.hover:
      return "#9CA3AF";
    case ClickableIconState.pressed:
      return "#6B7280";
  }
}
