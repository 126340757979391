import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { CTA } from "../../../constants/text";
import { Intercom } from "../../../constants/destinations";
import Section from "../../../containers/Section";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import Header, { HeaderVariant } from "../../Header";
import { IconType } from "../../Icon";
import FeatureCard from "../FeatureCard";
import { Name, Type, Location } from "../../../utils/analytics/constants";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ManagedServiceSection: React.FC<{}> = props => {
  const {t} = useTranslation();
  const headshots = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "dsp/headshots.png" }) {
        childImageSharp {
          fluid(maxWidth: 628, maxHeight: 540) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Section>
      <div className="flex flex-col lg:flex-row w-full items-center">
        <div className="w-full lg:w-1/2 h-full max-w-156">
          <Img
            fluid={headshots?.file?.childImageSharp?.fluid}
            className="place-self-center w-full h-full"
          />
        </div>
        <div className="flex flex-col lg:w-1/2">
          <Header
            variant={HeaderVariant.h2}
            className="text-center md:text-left"
          >
            {t("Amazon DSP managed service")}
          </Header>
          <div className="space-y-16">
            <div className="text-2xl text-gray-3-dark md:font-semibold text-center md:text-left">
              {t("You set goals, we work to exceed them.")}
              <br />
              <br />
              {t("Whether you're looking to maximize reach, grow incremental profits, or optimize towards custom metrics, our team of experts is here to ensure success is targeted to your KPIs.")}
            </div>
            <div className="flex flex-col justify-center space-y-12 mt-18 md:grid md:grid-cols-2 md:grid-rows-2 md:space-y-0 md:gap-y-12 md:gap-x-6 lg:mt-0 lg:place-self-center">
              <FeatureCard
                icon={IconType.book}
                feature={t("Media planning and strategy development")}
              />
              <FeatureCard
                icon={IconType.global}
                feature={t("World class campaign setup and execution")}
              />
              <FeatureCard
                icon={IconType.optimize}
                feature={t("Always-on campaign optimizations")}
              />
              <FeatureCard
                icon={IconType.dollar}
                feature={t("Budget pacing and delivery forecasting")}
              />
            </div>
            <Button
              id="features-da-appointment"
              variant={ButtonVariant.primary}
              size={ButtonSize.large}
              text={t(CTA.Book)}
              className={"w-full md:w-max"}
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ManagedServiceSection;
