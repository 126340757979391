import classNames from "classnames";
import React from "react";
import Icon, { IconType } from "../../Icon";
import Bullet, { BulletSize, BulletVariant } from "../../Bullet";

interface FeatureCardProps {
  icon: IconType;
  feature: string;
}

const FeatureCard: React.FC<FeatureCardProps> = props => {
  const { icon, feature } = props;

  return (
    <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5">
      <div className="place-self-center h-14">
        <Bullet
          iconType={icon}
          size={BulletSize.default}
          variant={BulletVariant.blue}
        />
      </div>
      <div
        className={classNames(
          "text-xl text-gray-1 text-center md:text-left w-60 self-center"
        )}
      >
        {feature}
      </div>
    </div>
  );
};

export default FeatureCard;
