import React from "react";

interface BulletPointProps {
  text: string;
}

const BulletPoint: React.FC<BulletPointProps> = props => {
  const { text } = props;

  return (
    <div className="relative">
      <div className="absolute w-2 h-2 bg-blue-1 rounded-full ring-4 ring-blue-2 left-1 top-2" />
      <div className="ml-9 text-gray-1">{text}</div>
    </div>
  );
};

export default BulletPoint;
