import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { CTA } from "../../../constants/text";
import { Intercom } from "../../../constants/destinations";
import Section from "../../../containers/Section";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import Header, { HeaderVariant } from "../../Header";
import BulletPoint from "./BulletPoint";
import { ClickableIconState, ClickableIconType } from "./ClickableIcon/models";
import ToggleFeatureCard from "./ToggleFeatureCard";
import { Name, Type, Location } from "../../../utils/analytics/constants";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AdvertisingSoftwareSection: React.FC<{}> = props => {
  const {t} = useTranslation();
  const [
    selectedMockVariant,
    setSelectedMockVariant
  ] = React.useState<DSPShowcaseVariant>(DSPShowcaseVariant.reporting);
  const [
    reportingState,
    setReportingState
  ] = React.useState<ClickableIconState>(ClickableIconState.active);
  const [
    executionState,
    setExecutionState
  ] = React.useState<ClickableIconState>(ClickableIconState.inactive);

  return (
    <div className="bg-blue-3-light rounded-tl-small rounded-br-small md:rounded-tl-large md:rounded-br-large pt-20 lg:py-36 overflow-hidden">
      <Section>
        <div className="flex flex-col lg:flex-row">
          <div className="flex flex-col lg:w-1/2">
            <Header
              variant={HeaderVariant.h2}
              className="text-center md:text-left"
            >
              {t("Amazon DSP advertising software")}
            </Header>
            <div className="text-xl md:text-2xl text-gray-3-dark md:font-semibold text-center md:text-left lg:hidden">
              {t("Software designed to help you create, manage and automate your Amazon DSP campaigns.")}
            </div>
            <div className="text-2xl text-gray-3-dark font-semibold hidden lg:inline">
              {t("Measuring profitability and growing incremental sales isn't a feature of Perpetua - it's the foundation upon which our Amazon DSP software is built.")}
            </div>
            <div className="flex flex-col md:flex-row my-18 space-y-12 md:space-y-0 md:justify-between">
              <div
                onMouseEnter={() =>
                  setReportingState(
                    selectedMockVariant === DSPShowcaseVariant.reporting
                      ? ClickableIconState.active
                      : ClickableIconState.hover
                  )
                }
                onMouseLeave={() =>
                  setReportingState(
                    selectedMockVariant === DSPShowcaseVariant.reporting
                      ? ClickableIconState.active
                      : ClickableIconState.inactive
                  )
                }
                onMouseDown={() =>
                  setReportingState(
                    selectedMockVariant === DSPShowcaseVariant.reporting
                      ? ClickableIconState.active
                      : ClickableIconState.pressed
                  )
                }
              >
                <ToggleFeatureCard
                  clickableIcon={ClickableIconType.chart}
                  feature={t("Holistic Analytics & Reporting")}
                  state={reportingState}
                  onClick={() => {
                    setSelectedMockVariant(DSPShowcaseVariant.reporting);
                    setReportingState(ClickableIconState.active);
                    setExecutionState(ClickableIconState.inactive);
                  }}
                />
              </div>
              <div
                onMouseEnter={() =>
                  setExecutionState(
                    selectedMockVariant === DSPShowcaseVariant.execution
                      ? ClickableIconState.active
                      : ClickableIconState.hover
                  )
                }
                onMouseLeave={() =>
                  setExecutionState(
                    selectedMockVariant === DSPShowcaseVariant.execution
                      ? ClickableIconState.active
                      : ClickableIconState.inactive
                  )
                }
                onMouseDown={() =>
                  setExecutionState(
                    selectedMockVariant === DSPShowcaseVariant.execution
                      ? ClickableIconState.active
                      : ClickableIconState.pressed
                  )
                }
              >
                <ToggleFeatureCard
                  clickableIcon={ClickableIconType.replay}
                  feature={t("Automated Campaign Execution")}
                  state={executionState}
                  onClick={() => {
                    setSelectedMockVariant(DSPShowcaseVariant.execution);
                    setExecutionState(ClickableIconState.active);
                    setReportingState(ClickableIconState.inactive);
                  }}
                />
              </div>
            </div>
            <div className="text-center md:text-left text-gray-1">
              {selectedMockVariant === DSPShowcaseVariant.reporting
                ? t(`Account-level performance metrics are limiting. Perpetua's Amazon DSP reporting engine gives you rich, robust KPIs at the product-level, and holistically across your entire Amazon business.`)
                : `Measuring profitability and growing incremental sales isn't a feature of Perpetua - it's the foundation upon which our Amazon DSP software is built.`}
            </div>
            <div className="mt-10 space-y-4">
              {selectedMockVariant === DSPShowcaseVariant.reporting && (
                <>
                  <BulletPoint
                    text={
                      t("Visibility to total spend, total sales, blended ACOS and organic sales across Sponsored Ads and Amazon DSP")
                    }
                  />
                  <BulletPoint
                    text={
                      t("Rich performance metrics at the ASIN-level; no need to rely on excel exports")
                    }
                  />
                  <BulletPoint
                    text={
                      t("Extensive analytics at the campaign, inventory and audience levels")
                    }
                  />
                </>
              )}
              {selectedMockVariant === DSPShowcaseVariant.execution && (
                <>
                  <BulletPoint
                    text={
                      t("Exclusive, curated full-funnel strategies, inventory and audiences maximize the impact")
                    }
                  />
                  <BulletPoint
                    text={
                      t("Streamlined creative setup unlocks testing and optimization at scale")
                    }
                  />
                  <BulletPoint
                    text={
                      t("Multi-plan goals; execute multiple tactics in a single goal")
                    }
                  />
                </>
              )}
            </div>
            <Button
              id="features-da-earlyaccess"
              variant={ButtonVariant.primary}
              size={ButtonSize.large}
              text={t(CTA.Access)}
              className={"w-full md:w-max mt-18"}
            />
          </div>
          <DSPShowcase variant={selectedMockVariant} />
        </div>
      </Section>
    </div>
  );
};
enum DSPShowcaseVariant {
  reporting = "reporting",
  execution = "execution"
}

interface DSPShowcaseProps {
  variant: DSPShowcaseVariant;
}

const DSPShowcase: React.FC<DSPShowcaseProps> = props => {
  const { variant } = props;

  const mocks = useStaticQuery(graphql`
    query {
      reporting: file(relativePath: { eq: "dsp/mocks/reporting.png" }) {
        childImageSharp {
          fluid(maxWidth: 1092) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      execution: file(relativePath: { eq: "dsp/mocks/execution.png" }) {
        childImageSharp {
          fluid(maxWidth: 1092) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="mt-18 lg:mt-0 lg:ml-16 self-center lg:relative lg:w-1/2 w-full h-48 md:h-96 lg:h-168">
      <Img
        fluid={mocks?.reporting?.childImageSharp?.fluid}
        style={{
          borderRadius: 12,
          filter: "drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.32))"
        }}
        className={classNames(
          "lg:absolute transition-opacity ease-in duration-400",
          {
            "h-0 w-0 opacity-0": variant !== DSPShowcaseVariant.reporting,
            "h-48 md:h-96 lg:h-168 w-full lg:w-256 opacity-100":
              variant === DSPShowcaseVariant.reporting
          }
        )}
      />
      <Img
        fluid={mocks?.execution?.childImageSharp?.fluid}
        style={{
          borderRadius: 12,
          filter: "drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.32))"
        }}
        className={classNames(
          "lg:absolute transition-opacity ease-in duration-400",
          {
            "h-0 w-0 opacity-0": variant !== DSPShowcaseVariant.execution,
            "h-48 md:h-96 lg:h-168 w-full lg:w-256 opacity-100":
              variant === DSPShowcaseVariant.execution
          }
        )}
      />
    </div>
  );
};

export default AdvertisingSoftwareSection;
