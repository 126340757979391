import React from "react";
import { ClickableIconProps } from "../models";
import { mapStateToFill } from "../utils";

const ChartClickableIcon: React.FC<ClickableIconProps> = props => {
  const { state } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.5 0C0.671573 0 0 0.671573 0 1.5V22.5C0 23.3284 0.671573 24 1.5 24H22.5C23.3284 24 24 23.3284 24 22.5V1.5C24 0.671573 23.3284 0 22.5 0H1.5ZM12.7689 18.1022C13.0748 18.4081 13.5754 18.3924 13.8616 18.068L21.1458 9.81263C21.2667 9.67561 21.3334 9.49916 21.3334 9.31642V7.58357C21.3334 6.89258 20.4782 6.56923 20.021 7.08736L13.8616 14.068C13.5754 14.3924 13.0748 14.4081 12.7689 14.1022L8.53041 9.86366C8.23752 9.57077 7.76264 9.57076 7.46975 9.86366L2.88642 14.447C2.74577 14.5876 2.66675 14.7784 2.66675 14.9773V16.856C2.66675 17.5242 3.4746 17.8588 3.94708 17.3863L7.46975 13.8637C7.76264 13.5708 8.23752 13.5708 8.53041 13.8637L12.7689 18.1022Z"
        fill={mapStateToFill(state)}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0"
          y1="0"
          x2="24.9201"
          y2="0.996805"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6366F1" />
          <stop offset="1" stop-color="#42A5F5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ChartClickableIcon;
