import React from "react";

import ChartClickableIcon from "./Chart";
import { ClickableIconState, ClickableIconType } from "./models";
import ReplayClickableIcon from "./Replay";

export interface GeneralClickableIconProps {
  clickableIconType: ClickableIconType;
  state: ClickableIconState;
}

const ClickableIcon: React.FC<GeneralClickableIconProps> = props => {
  const { clickableIconType, state } = props;
  switch (clickableIconType) {
    case ClickableIconType.chart:
      return <ChartClickableIcon state={state} />;
    case ClickableIconType.replay:
      return <ReplayClickableIcon state={state} />;
  }
};

export default React.memo(ClickableIcon);
