import React from "react";
import { ClickableIconProps } from "../models";
import { mapStateToFill } from "../utils";

const ReplayClickableIcon: React.FC<ClickableIconProps> = props => {
  const { state } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1193 24C17.2557 24 21.7891 20.8053 23.5154 16.0919L24 14.7689L21.3972 13.7836L20.9127 15.1066C19.5915 18.7137 16.0968 21.1765 12.1193 21.1765C6.95075 21.1765 2.77734 17.0584 2.77734 12C2.77734 6.94156 6.95075 2.82353 12.1193 2.82353C15.1792 2.82353 17.9693 4.28023 19.6961 6.62968L17.9854 8.34044C17.742 8.58383 17.9144 9 18.2586 9H21.0444L21.1088 9.12539L21.345 9H23.2273C23.654 9 24 8.65404 24 8.22727V3.25857C24 2.91436 23.5838 2.74197 23.3404 2.98537L21.6901 4.63568C19.4329 1.76041 15.9324 0 12.1193 0C5.43512 0 0 5.363 0 12C0 18.637 5.43512 24 12.1193 24Z"
        fill={mapStateToFill(state)}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="24"
          y1="0"
          x2="-0.920127"
          y2="0.996805"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6366F1" />
          <stop offset="1" stop-color="#42A5F5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ReplayClickableIcon;
