import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import { IconType } from "../../Icon";
import FeatureCard from "../FeatureCard";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const MaximizeRelevanceSection: React.FC<{}> = props => {
  const {t} = useTranslation();
  return (
    <Section>
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col space-y-6 md:space-y-8 lg:max-w-xl lg:mr-16">
          <Header
            variant={HeaderVariant.h2}
            className="text-center md:text-left"
          >
            {t("Maximize relevance, scale with ease")}
          </Header>
          <div className="text-gray-3-dark text-xl md:text-2xl md:font-semibold text-center md:text-left">
            {t("Your brand and products everywhere with Perpetua for Amazon DSP advertising.")}
          </div>
        </div>
        <div className="flex flex-col justify-center space-y-12 mt-18 md:grid md:grid-cols-2 md:grid-rows-2 md:space-y-0 md:gap-y-12 md:gap-x-6 lg:mt-0 lg:place-self-center">
          <FeatureCard
            icon={IconType.search}
            feature={t("Find, attract and convert new-to-brand customers")}
          />
          <FeatureCard
            icon={IconType.eye}
            feature={
              t("Get full visibility to the customer journey with Amazon Marketing Cloud and Sizmek")
            }
          />
          <FeatureCard
            icon={IconType.store}
            feature={t("Tell your brand story with custom creative")}
          />
          <FeatureCard
            icon={IconType.video}
            feature={
              t("Amplify your reach and access exclusive inventory with OTT")
            }
          />
        </div>
      </div>
    </Section>
  );
};

export default MaximizeRelevanceSection;
